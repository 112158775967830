/**
 * 设置本地存储值
 * setStorage
 * @param { field } field
 */
const setStorage = function(key,value)  {
    let storageVal = JSON.stringify(value);
    window.localStorage.setItem(key,storageVal)
}

/**
 * 获取本地存储值
 * getStorage
 * @param { field } field
 */
const getStorage = function (field) {
    let storageVal = JSON.parse(window.localStorage.getItem(field))
    if(storageVal) {
        return storageVal
    } else {
        return ''
    }
}
/**
 * 获取cookie
 * getCookie
 * @param { String } name
 */
 const getCookie = function (name) {
    var prefix = name + "="
    var start = document.cookie.indexOf(prefix)
 
    if (start == -1) {
        return null;
    }
 
    var end = document.cookie.indexOf(";", start + prefix.length)
    if (end == -1) {
        end = document.cookie.length;
    }
 
    var value = document.cookie.substring(start + prefix.length, end)
    return unescape(value);
}

const  getQueryString = function (name) { 
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
    var r = window.location.search.substr(1).match(reg); 
    if (r != null) return unescape(r[2]); 
    return null; 
} 

// 验证邮箱
const checkUserEmail = (rule,value,callback) => {
    let reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
    if(!value) {
        return callback(new Error('メールアドレスを入力して下さい'))
    }
    setTimeout(() => {
        if(!reg.test(value)) {
            callback(new Error('メールアドレスに誤っています'))
        } else {
            callback()
        }
    },500)
}
/**
 * 处理增加项
 * addItem
 * @param { field } field
 */
const addItem = function (type = "input",arr) {
    if(type ==  "input") {
        if(arr.length > 0 && arr.length < 10 ) {
            let id = "";
            for(let i = 0;i < arr.length; i++){
                if(i == arr.length - 1) {
                    id = arr[i].id
                }
            }
            arr.push({
                id: id + 1,
                name: ''
            })
        } else if(arr.length > 9) {
            this.$message.error("最大10個まで")
        } else {
            arr.push({
                id: 0,
                name: ''
            })
        }
    } else if(type == "select") {
        if(arr.length > 0 && arr.length < 10) {
            arr.push({
				selfProdu: [
					{value:"1",label:"商業"},
					{value:"2",label:"自主制作"}
				],
				color: [
					{value:"1",label:"カラー"},
					{value:"2",label:"白黒"}
				],
				isSerial: [
					{value:"1",label:"読切"},
					{value:"2",label:"連載"}
				],
				serialSize: [
					{value:"1",label:"縦スク"},
					{value:"2",label:"見開き"}
				],
				selfProduVal: '',
				colorVal: '',
				isSerialVal: '',
				serialSizeVal: "",
				workName: '',
				author: '',
				desc:''
			})
        } else if(arr.length > 9) {
            this.$message.error("最大10個まで")
        } else {
            arr.push({
				selfProdu: [
					{value:"1",label:"商業"},
					{value:"2",label:"自主制作"}
				],
				color: [
					{value:"1",label:"カラー"},
					{value:"2",label:"白黒"}
				],
				isSerial: [
					{value:"1",label:"読切"},
					{value:"2",label:"連載"}
				],
				serialSize: [
					{value:"1",label:"縦スク"},
					{value:"2",label:"見開き"}
				],
				selfProduVal: '1',
				colorVal: '1',
				isSerialVal: '1',
				serialSizeVal: "1",
				workName: '',
				author: '',
				desc:''
			})
        }
    }
}
/**
 * 处理删除项
 * deleteItem
 * @param { field } field
 */
const deleteItem = function (arr,index)  {
    arr.splice(index,1)
}

/**
 * 解析数据
 * addItem
 * @param { field } field
 */
const resolvingData = function (type,arr) {
    let wantArr = [];
    if(type == "input") {
        if(arr.length) {
            for(let i = 0;i < arr.length; i++) {
                wantArr.push({
                    name: arr[i],
                    id: i
                })
            }
            return wantArr
        }
    } else if(type == "select") {
        if(arr.length) {
            let arr1 = [];
            for(let i = 0;i < arr.length; i++) {
                arr1 = arr[i].split(";")
                wantArr.push({
                    selfProdu: [
						{value:"1",label:"商業"},
					    {value:"2",label:"自主制作"}    
					],
					color: [
						{value:"1",label:"カラー"},
					    {value:"2",label:"白黒"}
					],
					isSerial: [
						{value:"1",label:"読切"},
					    {value:"2",label:"連載"}
					],
					serialSize: [
						{value:"1",label:"縦スク"},
					    {value:"2",label:"見開き"}  
					],
                    selfProduVal: arr1[0],
					colorVal: arr1[1],
					isSerialVal: arr1[2],
					serialSizeVal: arr1[3],
					workName: arr1[4],
					author: arr1[5],
					desc: arr1[6]
                })
            }
            return wantArr
        }
    } else {
        if(arr.length) {
            let lang = [];
            for(let i = 0;i < arr.length;i++) {
                lang = arr[i].split(";")
                wantArr.push( 
                    {
						lang: [],
						fromLanguageVal: lang[0],
						toLanguageVal: lang[1],
					}
                )
            }
            return wantArr
        }
    }

}
export default {
    getStorage,
    setStorage,
    addItem,
    deleteItem,
    checkUserEmail,
    getCookie,
    getQueryString,
    resolvingData
}